import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  seo,
  intro,
  main,
  stages,
  products,
  industries,
  form,
} from '../content/omida-trade'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Stages from '../sections/omida-trade/Stages'
import IndexIndustries from '../sections/IndexIndustries'
import FormMail from '../components/FormMail'
import { sButton, sButtonGlow } from '../components/Button'
import { s } from '../style'
import TradePosts from '../sections/omida-trade/TradePosts'
import TradeAdditionalText from '../sections/omida-trade/TradeAdditionalText'
import Containers from '../sections/omida-trade/Products'

const OmidaTrade = () => {
  const { lang } = useLangContext()
  const { image } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/omida-trade.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: JPG
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/intros/omida-trade.jpg'}
        url={seo.url}
      />
      <Intro
        title={intro.title[lang]}
        desc={intro.desc[lang]}
        image={image.childImageSharp}
        logo
        h={2}
        button={intro.button}
        buttonSecond={intro.buttonSecond}
      />
      <Main data={main} h={1} />

      <TradeAdditionalText />
      <TradePosts />
      <IndexIndustries
        include={industries.include}
        title={industries.title[lang]}
        desc={industries.desc[lang]}
      />
      <Stages data={stages} />
      <Containers data={products} />
      <FormMail data={form} template='OmidaTrade' />
      <div css={{ paddingBottom: '4rem' }} />
      <a href='tel:+48571229553' css={[sButton, sButtonGlow, sCallButton]}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
          viewBox='0 0 20 20'
          fill='currentColor'>
          <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
        </svg>
      </a>
    </Layout>
  )
}

const sCallButton = {
  cursor: 'pointer',

  position: 'fixed',
  width: 64,
  height: 64,
  bottom: 32,
  left: 32,
  zIndex: 2000,
  marginBottom: 0,
  padding: 0,
  svg: { cursor: 'pointer', width: 32, height: 32 },
  [s.xs]: {
    width: 48,
    height: 48,
    svg: {
      width: 24,
      height: 24,
    },
  },
}

export default OmidaTrade
