import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ContainersLarge from '../../components/ContainersLarge'

const Containers = ({ data }) => {
  const query = useStaticQuery(graphql`
    {
      containers: allContentfulKontener(
        filter: { node_locale: { eq: "pl" } }
        sort: { order: ASC, fields: containerName }
      ) {
        edges {
          node {
            containerName
            containerDesc {
              childMarkdownRemark {
                html
              }
            }
            childContentfulKontenerSizesJsonNode {
              tables {
                sizes {
                  external {
                    Height
                    Length
                    Width
                  }
                  internal {
                    Height
                    Length
                    Width
                  }
                }
                weight {
                  gross
                  payload
                  tare
                }
              }
            }
            containerImage {
              gatsbyImageData(
                width: 1600
                quality: 50
                placeholder: DOMINANT_COLOR
                formats: JPG
              )
            }
          }
        }
      }
    }
  `)
  return <ContainersLarge query={query} data={data} />
}

export default Containers
