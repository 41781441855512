import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import alt from '../content/alt'
import { useLangContext } from '../context/lang.context'
import { grid, s, colors, alpha } from '../style'
import Button from './Button'
import H from './H'
import Logo from './Logo'

const ContainerLarge = ({ container, last, h, minimal }) => {
  const { lang } = useLangContext()

  // console.log(container)
  const data = container.node

  const desc = data.containerDesc.childMarkdownRemark.html
  const sizes = data.childContentfulKontenerSizesJsonNode.tables.sizes
  const weight = data.childContentfulKontenerSizesJsonNode.tables.weight
  const image = getImage(data.containerImage)

  return (
    <div css={[sContainer, last && { [s.md]: { marginBottom: 0 } }]}>
      <div css={[sLeft, minimal && sLeftMinimal]}>
        <div
          css={{
            [s.sm_down]: {
              height: 300,
              borderTopLeftRadius: 32,
              borderTopRightRadius: 32,
            },
            [s.md]: { height: 660 },
          }}>
          <GatsbyImage
            image={image}
            alt={data.containerName}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'center center',
            }}
            style={{
              height: '100%',
              borderRadius: 'inherit',
            }}
          />
        </div>

        <div css={sDimmer} />
        <div css={sDimmer2} />
        {minimal && <div css={sDimmerMinimal} />}
        <div css={sTextField}>
          <div css={{ position: 'relative' }}>
            <span className='product-omida-logo'>
              <Logo />
            </span>
            <H
              h={h}
              className='product-name'
              css={
                data.containerName
                  .split(' ')
                  .map((e) => e.length)
                  .reduce((sum, curr) => (sum > curr ? sum : curr), 0) > 11 && {
                  [s.xs]: { fontSize: '2rem !important', paddingTop: '1.5rem' },
                }
              }>
              {data.containerName}
            </H>
          </div>
        </div>
      </div>

      <div css={[sRight, minimal && { [s.md]: { display: 'none' } }]}>
        {desc && (
          <div 
            className='container-details'
            dangerouslySetInnerHTML={{
            __html: desc,
          }} />
        )}
        <table css={sTable}>
          <thead>
            <tr>
              <th></th>
              <th>Wymiary zew.</th>
              <th>Wymiary wew.</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th>Długość</th>
              <td>{sizes.external.Length}</td>
              <td>{sizes.internal.Length}</td>
            </tr>
            <tr>
              <th>Szerokość</th>
              <td>{sizes.external.Width}</td>
              <td>{sizes.internal.Width}</td>
            </tr>
            <tr>
              <th>Wysokość</th>
              <td>{sizes.external.Height}</td>
              <td>{sizes.internal.Height}</td>
            </tr>
          </tbody>
        </table>

        <table css={sTable}>
          <thead>
            <tr>
              <th>Tara</th>
              <th>Maks. ładunek</th>
              <th>Maks. brutto</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{weight.tare}</td>
              <td>{weight.payload}</td>
              <td>{weight.gross}</td>
            </tr>
          </tbody>
        </table>

        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Button
            noMargin
            dimmed
            fullwidth
            extraCss={{ fontSize: 12, [s.lg]: { whiteSpace: 'nowrap' } }}
            link={`/trade/#formularz`}>Szybka wycena
          </Button>
        </div>
      </div>
    </div>
  )
}

const sContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  color: colors.white,
  zIndex: 10,
  overflow: 'hidden',
  backgroundColor: colors.greyDark,
  boxShadow: `0px 16px 32px ${colors.black.concat(alpha[8])}`,
  [s.sm_down]: {
    borderRadius: 32,
    marginBottom: '2rem',
  },
  [s.md]: {
    borderRadius: 64,
    height: 660,
    marginBottom: '4rem',
  },
}

const sDimmer = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: `linear-gradient(to bottom right, ${colors.greyDark.concat(
    alpha[50]
  )}, ${colors.greyDarker})`,
  opacity: 0.8,
}
const sDimmer2 = {
  position: 'absolute',
  width: '25%',
  height: '100%',
  right: 0,
  top: 0,
  background: `linear-gradient(92deg, ${colors.greyDark.concat(
    alpha[0]
  )}, ${colors.greyDarker.concat(alpha[0])} 33%, ${colors.greyDarker.concat(
    alpha[60]
  )})`,
  opacity: 0.6,
}

const sDimmerMinimal = {
  [s.sm_down]: { display: 'none' },
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: `linear-gradient(to top right, ${colors.greyDarker.concat(
    alpha[100]
  )}, ${colors.greyDarker.concat(alpha[0])})`,
}

const sTextField = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [s.sm_down]: {
    justifyContent: 'center',
    left: '4rem',
    width: 'calc(100% - 4rem)',
  },

  [s.xs]: {
    left: '2rem',
    width: 'calc(100% - 2rem)',
  },

  [s.md]: {
    padding: '96px 64px 64px',
  },

  [s.lg]: {
    padding: '128px 96px 96px',
  },

  '.product-name': {
    fontWeight: 900,
    textTransform: 'capitalize',
    letterSpacing: '-0.1em',
    marginTop: '.5em',

    [s.xs]: {
      lineHeight: '1em',
      fontSize: '2rem',
      paddingTop: '0.5rem',
    },
    [s.md]: {
      fontSize: '3rem',
    },
    [s.lg]: {
      fontSize: '4rem',
      lineHeight: '1.25',
    },
  },

  '.product-omida-logo': {
    position: 'absolute',
    [s.sm_down]: {
      top: -6,
      left: 5,
    },
    [s.md]: {
      top: -4,
      left: 4,
    },
    [s.lg]: {
      top: 0,
      left: 8,
    },
  },

  '.product-desc': {
    fontSize: '0.875rem',
    lineHeight: '2em',
    maxWidth: '60%',
    opacity: 0.6,
    [s.lg]: {
      fontSize: '1rem',
    },
    [s.sm_down]: { display: 'none' },
  },
}

const sLeft = {
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  top: 0,
  left: 0,

  [s.xs]: {
    maxWidth: grid(12),
    flexBasis: grid(12),
  },
  [s.sm_down]: {
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
  },
  [s.sm]: {
    maxWidth: grid(12),
    flexBasis: grid(12),
  },
  [s.md]: {
    maxWidth: grid(6),
    flexBasis: grid(6),
  },
  [s.lg]: {
    maxWidth: grid(7),
    flexBasis: grid(7),
  },
}

const sLeftMinimal = {
  [s.md]: {
    maxWidth: grid(12),
    flexBasis: grid(12),
  },
  [s.lg]: {
    maxWidth: grid(12),
    flexBasis: grid(12),
  },
}

const sRight = {
  display: 'flex',
  flexDirection: 'column',

  background: `linear-gradient(to bottom left, #666666, ${colors.greyDarker})`,
  [s.sm_down]: {
    padding: '2rem 1.5rem',
  },
  [s.xs]: {
    maxWidth: grid(12),
    flexBasis: grid(12),
  },
  [s.sm]: {
    maxWidth: grid(12),
    flexBasis: grid(12),
  },
  [s.md]: {
    padding: '4rem 2rem',
    maxWidth: grid(6),
    flexBasis: grid(6),
  },
  [s.lg]: {
    padding: '4rem',
    maxWidth: grid(5),
    flexBasis: grid(5),
  },
  justifyContent: 'space-between',

  '.product-desc-mobile': {
    fontSize: '0.75rem',
    opacity: 0.6,
    lineHeight: 1 * 1.618 + 'rem',
    marginBottom: '2rem',
    [s.md]: { display: 'none' },
  },
  '.product-para-title': {
    fontSize: '1rem',
    opacity: 0.8,
    marginBottom: '1rem',
    fontWeight: 700,
    letterSpacing: '-0.01em',
    [s.xs]: {
      marginBottom: '0.5rem',
    },
  },
  '.product-para-text': {
    fontSize: '0.75rem',
    letterSpacing: '-0.01em',
    marginBottom: '2rem',
    lineHeight: 1 * 1.618 + 'rem',
    [s.md]: {
      marginBottom: 0,
    },
    opacity: 0.4,
  },
  '.container-details': {
    'h4': {
      fontSize: '1rem',
      opacity: 0.8,
      marginBottom: '1rem',
      fontWeight: 700,
      letterSpacing: '-0.01em',
      [s.xs]: {
        marginBottom: '0.5rem',
      },
    },
    'p': {
      fontSize: '0.875rem',
      letterSpacing: '-0.01em',
      marginBottom: '2rem',
      lineHeight: 1 * 1.618 + 'rem',
      [s.md]: {
        marginBottom: 0,
      },
      opacity: 0.4,
    }
  }
}

const sTable = {
  borderCollapse: 'collapse',
  textAlign: 'left',
  marginBottom: '30px',
  fontSize: '.9rem',
  opacity: '0.8',
  'td, th': {
    padding: '.1rem .25rem',
    border: '1px solid #fff',
  },
  'thead th:first-of-type': {
    borderTop: '0',
    borderLeft: '0'
  },
  ':nth-of-type(2) thead th:first-of-type': {
    borderTop: '1px solid #fff',
    borderLeft: '1px solid #fff'
  },
  [s.md]: {
    marginBottom: '30px',
  },
}

export default ContainerLarge
