export const seo = {
  url: 'omida-trade',
  title: {
    pl: 'Kontenery morskie nowe i używane | Sprzedaż kontenerów | Omida Trade',
    en: 'Intermodal Transport | Omida Transport of the future ',
  },
  desc: {
    pl: '✓Kontener morski ✓Sprzedaż kontenerów ✓Dystrybucja kontenerów ✓Kontener z logo ✓Producent kontenerów ✓Kontenery na wymiar ✓Szybka realizacja',
    en: 'Long-term contract, warranty, cargo safety, time optimization and up to 50% savings. Intermodal transport with an experienced TSL company.',
  },
  keywords: [
    'transport intermodalny',
    'intermodal',
    'długoterminowa umowa',
    'optymalizacja',
    'tsl',
  ],
}

export const intro = {
  title: {
    pl: 'Trade',
    en: 'Trade',
  },
  desc: {
    pl: 'Sprzedaż i dystrybucja kontenerów nowych i używanych.',
    en: 'Intermodal Transport is a solution that allows you to reduce transport costs on specific routes and directions. It is a transport in which we use several forms of transport in one transport unit along the entire route.',
  },
  buttonSecond: {
    text: 'Wyślij zapytanie',
    link: '/trade/#formularz',
  },
  button: {
    text: 'Czytaj więcej',
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    pl: 'Sprzedaż i dystrybucja kontenerów',
    en: 'Intermodal transport - take advantage of the transport possibilities of the future',
  },
  texts: [
    {
      pl: `<span>Omida Trade w swoich usługach posiada możliwość, wyprodukowania kontenerów morskich, pod specjalne zamówienie klienta.</span>`,
      en: `<span>In intermodal transport it is possible to prepare <strong>a long-term contract with a price guarantee</strong>. This solution is dedicated to companies that want to obtain a guarantee of stability of transport costs throughout the year.</span>`,
    },
    {
      pl: `Realizujemy zamówienia na kontenery morskie od 3 do 13 metrów. Realizujemy usługę pełnej personalizacji kontenerów - możliwość wyboru kolorystyki RAL oraz naniesienia loga Klienta na kontener. Produkujemy kontenery pod specjalne zamówienie z dodatkowymi drzwiami, wentylacją, czy zabezpieczeniem.`,
      en: `<span>The advantages of intermodal transport are <strong>cargo safety and transport time optimization</strong>. The goods in motion are not exposed to unfavorable external factors.</span>`,
    },
  ],

  features: [
    {
      icon: 'time',
      subtitle: {
        pl: '',
        en: '',
      },
      title: {
        pl: 'Szybka realizacja',
        en: 'Ecology',
      },
      desc: {
        pl: 'Realizujemy nasze zamówienia w możliwie krótkim czasie.',
        en: 'Intermodal transport allows to reduce the emission of harmful substances for our health and for our planet by up to 60%',
      },
    },
    {
      icon: 'settings',
      subtitle: {
        pl: '',
        en: '',
      },
      title: {
        pl: 'Personalizacja',
        en: 'Safety',
      },
      desc: {
        pl: 'Możliwość zamówienia kontenera pod wymiar oraz naniesienia logo Twojej firmy na kontener.',
        en: 'Intermodal transport minimizes the possibility of undesirable events and random situations - damage or loss of goods, accidents and theft.',
      },
    },
    {
      icon: 'dollar',
      subtitle: {
        pl: '',
        en: '',
      },
      title: {
        pl: 'Konkurencyjne ceny',
        en: 'Optimization',
      },
      desc: {
        pl: 'Posiadamy optymalne rozwiązania dla Twojego biznesu oraz ofertę handlową z atrakcyjnymi stawkami.',
        en: 'Intermodal transport, thanks to timely contracts, guarantees a constant price for the transport of Goods - which often reduces costs by up to 50% compared to road transport.',
      },
    },
  ],
}

export const stages = {
  id: 'etapy',
  title: {
    pl: 'Proces Zamówienia Kontenerów',
    en: 'Processes - Intermodal Transport',
  },
  desc: {
    pl: ' ',
    en: 'Learn about the Omida Intermodal service processes',
  },
  stage: {
    pl: 'Etap',
    en: 'Stage',
  },
  stages: [
    {
      number: '01',
      title: {
        pl: 'Dopasowanie rozmiaru',
        en: 'Reception',
      },
      body: {
        pl: `Nasi specjaliści dobierają odpowiedni kontener do potrzeb Twojego biznesu`,
        en: `<ul><li>At this stage, we collect our client's goods and transport them by tractor and semi-trailer to the railway terminal.</li><li> We offer goods collection as part of intermodal transport within a radius of 300 km from the railway terminals.</li></ul>`,
      },
    },
    {
      number: '02',
      title: {
        pl: 'Zamówienie i formalności',
        en: 'Delivery to the Terminal',
      },
      body: {
        pl: `Zajmujemy się dokumentacją oraz formalnościami na etapie zamówienia, produkcji oraz dostawy.Realizujemy możliwość naniesienia własnego loga na kontenerze oraz wyboru koloru kontenera.`,
        en: '<ul><li>After our driver takes over the goods, the loaded trailer is delivered to the terminal.</li><li> Drivers driving in wheels on the routes served, in an intermodal product, obtain the best delivery times.</li> </ul>',
      },
    },
    {
      number: '03', 
      title: {
        pl: 'Dostawa do Portu',
        en: 'Lashing the Cargo',
      },
      body: {
        pl: `Dostarczamy kontener do wskazanego portu przeładunkowego. Twój kontener jest już gotowy do dostawy na adres wskazany w zamówieniu.`,
        en: `
        <ul>
          <li>After reaching the place of loading, the trailer is transferred to a dedicated wagon.</li>
          <li>Reach stackers and cranes are used for this purpose.</li>
          <li>When the semi-trailer is loaded onto the rolling stock, the tractor driver can make subsequent deliveries and pick-ups within a radius of 300 km from the railway terminal.</li>
      </ul>
        `,
      },
    },
    {
      number: '04',
      title: {
        pl: 'Podróż do Zamawiającego ',
        en: 'Carriage',
      },
      body: {
        pl: `Dostarczamy kontener pod wskazany adres na terenie całej Europy. Skontaktuj się z nami w celu ustalenia kosztów transportu na wybrany adres.`,
        en: `
        <ul>
        <li>At a certain time, almost every day, the entire train depot starts from the terminal to transport the goods to their final destination.</li>
        <li>The key advantage in rail transport is the continuous movement of our goods.</li>
      </ul>
        `,
      },
    },
    {
      number: '05',
      title: {
        pl: 'Rozładunek',
        en: 'Unloading',
      },
      body: {
        pl: `Dostarczamy kontener pod wskazany adres. Dzięki konstrukcji samonośnej kontenera wraz z własną podłogą możemy ustawić kontener na bloczkach oraz płytach betonowych.`,
        en: `
        <ul>
          <li>The cargo goes to the destination terminal, and then the trailer is unloaded from the wagon and awaits collection by our driver.</li>
          <li>After connecting the trailer to the tractor, the load is delivered to the pickup point in accordance with the guidelines agreed with our client.</li>
      </ul>
        `,
      },
    },
  ],
}

export const products = {
  id: 'produkty',
  title: {
    pl: 'Kontenery, sprawdź naszą ofertę',
    en: 'Build an advantage in industry, thanks to the OMIDA e-commerce services',
  },
  desc: {
    pl: '',
    en: 'High-end warehousing, supplier selection and wise management are key aspects of the so-called customer-experience',
  },
  products: [
    {
      image: 'con20',
      name: { pl: 'Kontener 20’DV - nowy', en: 'warehouse' },

      alt: 'Kontener 20’DV - nowy',
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Kontener 20’DV One Way - nowy',
            en: 'We have a modern warehouse space at our disposal',
          },
          text: {
            pl: 'To kontener, który jest po jednej podróży morskiej lub kolejowej. Ma drewnianą podłogę i ma 6 metrów długości. Kontener 20 stopowy to jeden z najpopularniejszych kontenerów w naszej ofercie, ze względu na jego wielkość i możliwość szybkiego transportu. Idealnie nadaje się na magazyn tymczasowy, ale także dalszy jego transport.',
            en: 'Our warehouse meets the requirements of class A warehouses located in central Europe. The e-commerce warehouse has an area of 5.5 thousand square meters and is 12 meters high, making it able to store more than 6,500 pallets already during the first stage of expansion!',
          },
        },
      ],
      tables: {
        sizes: {
          external: {
            Length: '6036 mm',
            Width: '2438 mm',
            Height: '2591 mm',
          },
          internal: {
            Length: '5898 mm',
            Width: '2362 mm',
            Height: '2393 mm',
          },
        },
        weight: {
          tare: '1970 kg',
          payload: '28510 kg',
          gross: '30480 kg',
        },
      },
      button: {
        pl: 'Szybka wycena',
        en: 'Select the Warehouse service',
      },
      link: '/trade/#formularz',
    },
    {
      image: 'con20',
      name: { pl: 'Kontener 20’DV - używany', en: 'warehouse' },

      alt: 'Kontener 20’DV - używany',
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Kontener 20’DV One Way - używany',
            en: 'We have a modern warehouse space at our disposal',
          },
          text: {
            pl: 'Kontener 20’DV to kontener, który jest w przedziale 10 - 16 lat. Ma drewnianą podłogę i ma 6 metrów długości. Kontener 20 stopowy to jeden z najpopularniejszych kontenerów w naszej ofercie, ze względu na jego wielkość i możliwość szybkiego transportu. Idealnie nadaje się na magazyn tymczasowy, ale także dalszy jego transport. Jesteśmy w stanie zaoferować Państwu tego typu kontenerów w stanie używanym, jak i tak samo nowym – po jednej podróży.',
            en: 'Our warehouse meets the requirements of class A warehouses located in central Europe. The e-commerce warehouse has an area of 5.5 thousand square meters and is 12 meters high, making it able to store more than 6,500 pallets already during the first stage of expansion!',
          },
        },
      ],
      tables: {
        sizes: {
          external: {
            Length: '6036 mm',
            Width: '2438 mm',
            Height: '2591 mm',
          },
          internal: {
            Length: '5898 mm',
            Width: '2362 mm',
            Height: '2393 mm',
          },
        },
        weight: {
          tare: '1970 kg',
          payload: '28510 kg',
          gross: '30480 kg',
        },
      },
      button: {
        pl: 'Szybka wycena',
        en: 'Select the Warehouse service',
      },
      link: '/trade/#formularz',
    },
    {
      image: 'con40',
      name: { pl: 'Kontener 40’DV - używany', en: 'warehouse' },

      alt: 'Kontener 40’DV - używany',
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Kontener 40’DV One Way - używany',
            en: 'We have a modern warehouse space at our disposal',
          },
          text: {
            pl: 'To kontener 12-metrowy o standardowej wielkości. Ma solidną drewnianą podłogę, mocne drzwi i dużą pojemność. Kontenery te posiadają widoczne ślady użytkowania oraz śladowe ilości rdzy. Jednakże jest to najwyższy kontener, który jest akceptowalny w świecie transportu.',
            en: 'Our warehouse meets the requirements of class A warehouses located in central Europe. The e-commerce warehouse has an area of 5.5 thousand square meters and is 12 meters high, making it able to store more than 6,500 pallets already during the first stage of expansion!',
          },
        },
      ],
      tables: {
        sizes: {
          external: {
            Length: '12192 mm',
            Width: '2438 mm',
            Height: '2591 mm',
          },
          internal: {
            Length: '12032 mm',
            Width: '2352 mm',
            Height: '2393 mm',
          },
        },
        weight: {
          tare: '3720 kg',
          payload: '26760 kg',
          gross: '30480 kg',
        },
      },
      button: {
        pl: 'Szybka wycena',
        en: 'Select the Warehouse service',
      },
      link: '/trade/#formularz',
    },
    {
      image: 'con40',
      name: { pl: 'Kontenery 40’HC  - Nowe & Używane', en: 'warehouse' },

      alt: 'Kontenery 40’HC  - Nowe & Używane',
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Kontenery 40’HC  - Nowe & Używane' ,
            en: 'We have a modern warehouse space at our disposal',
          },
          text: {
            pl: 'Kontenery HIGH CUBE zwany kontenerem HC są przeznaczone do ładunków transportów ponadgabarytowych. Ich wysokość jest o 30 cm wyższa od standardowego kontera.',
            en: 'Our warehouse meets the requirements of class A warehouses located in central Europe. The e-commerce warehouse has an area of 5.5 thousand square meters and is 12 meters high, making it able to store more than 6,500 pallets already during the first stage of expansion!',
          },
        },
      ],
      tables: {
        sizes: {
          external: {
            Length: '12192 mm',
            Width: '2438 mm',
            Height: '2896 mm',
          },
          internal: {
            Length: '12032 mm',
            Width: '2350 mm',
            Height: '2695 mm',
          },
        },
        weight: {
          tare: '3480 kg',
          payload: '27000 kg',
          gross: '30480 kg',
        },
      },
      button: {
        pl: 'Szybka wycena',
        en: 'Select the Warehouse service',
      },
      link: '/trade/#formularz',
    },
    {
      image: 'con40',
      name: { pl: 'Kontenery 45’HCPW - Używane', en: 'warehouse' },

      alt: 'Kontenery 45’HCPW - Używane',
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Kontenery 45’HCPW - Używane' ,
            en: 'We have a modern warehouse space at our disposal',
          },
          text: {
            pl: '45’HC Palletwide Kontener Podwyższany Poszerzany jest używanym kontenerem, który jest doskonale zaprojektowany, aby zaspokoić potrzeby transportu towarów o dużych gabarytach. Posiada wymiary wewnętrzne: długość 13,7 m, szerokość 2,4 m i wysokość 2,6 m. Kontener jest wyposażony w podwyższane i poszerzane boki, które umożliwiają łatwe załadunki i rozładunki towarów. Kontener jest wytrzymały i wyprodukowany z wysokiej jakości materiałów.',
            en: 'Our warehouse meets the requirements of class A warehouses located in central Europe. The e-commerce warehouse has an area of 5.5 thousand square meters and is 12 meters high, making it able to store more than 6,500 pallets already during the first stage of expansion!',
          },
        },
      ],
      tables: {
        sizes: {
          external: {
            Length: '13716 mm',
            Width: '2500 mm',
            Height: '2896 mm',
          },
          internal: {
            Length: '13559 mm',
            Width: '2432 mm',
            Height: '2696 mm',
          },
        },
        weight: {
          tare: '4860 kg',
          payload: '29140 kg',
          gross: '34000 kg',
        },
      },
      button: {
        pl: 'Szybka wycena',
        en: 'Select the Warehouse service',
      },
      link: '/trade/#formularz',
    },
  ],
}

export const industries = {
  title: {
    pl: 'Jakie branże korzystają z Omida Trade?',
    en: 'Which industries use Intermodal Transport?',
  },
  desc: {
    pl: ' ',
    en: 'See examples of the use of intermodal in maintaining the supply chain. We dedicate intermodal transport to companies operating for the protection of the environment, regularly sending large amounts of goods to their contractors.',
  },
  include: ['Motoryzacyjna', 'Budowlana', 'Produkcja'],
}

export const form = {
  id: 'formularz',
  title: {
    pl: 'Sprawdź naszą ofertę na kontenery dla Twojego biznesu',
    en: 'Check what savings you can generate by choosing Intermodal Transport',
  },
  desc: {
    pl: '',
    en: '',
  },
  fields: {
    email: {
      label: { pl: 'Email', en: 'Email' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    name: {
      label: { pl: 'Osoba Kontaktowa', en: 'Contact person' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    message: {
      label: { pl: 'Wiadomość', en: 'Message' },
      placeholder: { pl: '', en: '' },
      type: 'message',
      isRequired: true,
    },
  },
  agreements: [
    {
      pl: 'Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści pochodzących od Omida Logistics Sp. z o.o.',
      en: 'I agree to receive by e-mail commercial information coming from Omida Logistics Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
    },
    {
      pl: 'Wyrażam zgodę na wykorzystywanie przez Omida Logistics Sp. z o.o. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących celem przedstawienia informacji handlowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. - Prawo telekomunikacyjne',
      en: 'I hereby consent to the use by Omida Logistics Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
    },
  ],
  administrating: {
    pl: 'Administratorem Państwa danych osobowych będzie Omida Logistics spółka z ograniczoną odpowiedzialnością (dalej: Omida). Można się z nami skontaktować w następujący sposób: listownie na adres: Aleja Grunwaldzka 472C, 80-309 Gdańsk, przez e-mail:',
    en: 'The administrator of your personal data will be Omida Logistics spółka z ograniczoną odpowiedzialnością (hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
  },
  administratingSecond: {
    pl: ' lub telefonicznie: +48 58 741 88 14. Dane osobowe będziemy przetwarzać w celu przygotowania ofert dotyczących produktów i usług Omida. Szczegółowe informacje na temat przetwarzania danych osobowych znajdziesz',
    en: 'or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
  },
  administratingLinkText: {
    pl: 'tutaj',
    en: 'here',
  },
  dictonary: {
    success: {
      title: { pl: 'Dziękujemy!', en: 'Thank you!' },
      text: {
        pl: 'Twoja wiadomość została wysłana. Odpowiemy najszybciej jak to możliwe. Przesłaliśmy również potwierdzenie wiadomości na podany adres',
        en: 'Your message has been sent. We will reply as soon as possible. We also sent you the confirmation to provided email address',
      },
    },
    failure: {
      title: { pl: 'Niepowodzenie!', en: 'Failure!' },
      text: {
        pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
        en: 'It looks like your message has not been sent. Contact us by e - mail or by phone',
      },
    },
    send: {
      pl: 'Wyślij',
      en: 'Send',
    },
    fill: {
      pl: 'Wypełnij formularz',
      en: 'Fill up the form',
    },
    fillagreements: {
      pl: 'Zaznacz zgody',
      en: 'Agree to the terms',
    },
  },
}

export const buttonBack = {
  text: {
    pl: 'Zapytaj o ofertę',
    en: 'Ask for an offer',
  },
  link: '/transport-intermodalny/#formularz',
}

export const tradePosts = {
  title: {
    pl: 'Aktualności Omida Trade',
    en: 'Omida Intermodal Express News',
  },
  desc: {
    pl: '',
    en: '',
  },
}
